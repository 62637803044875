import { useEffect, useState } from 'react';
import type { RefObject } from 'react';

type Revision = {
  revision: string;
};

export const useDetectRevisionChanges = (
  bodyRef: RefObject<HTMLBodyElement>,
) => {
  const [changed, setChanged] = useState<boolean>(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    if (changed) return;

    const revision = bodyRef.current?.getAttribute('data-build-revision');
    if (!revision) return;

    (async () => {
      const res = await fetch('/revision.json');
      const json: Revision = await res.json();
      if (revision !== json.revision) {
        setChanged(true);
      }
    })();
  }, [bodyRef, changed]);

  return changed;
};
