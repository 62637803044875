import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/cloudflare';
import { json } from '@remix-run/cloudflare';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useNavigation,
  useRouteError,
} from '@remix-run/react';
import { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { ErrorPage } from 'app/components/common/ErrorPage';
import { LinearProgressIndicator } from 'app/components/common/LinearProgressIndicator';
import { LiveReload } from 'app/components/common/LiveReload';
import { SnackbarProvider } from '@monorepo_2022/app-utils/src/snackbar';
import { RequireFullDocumentLoadContext } from '@monorepo_2022/app-utils/src/contexts/requireFullDocumentLoadContext';
import { useDetectRevisionChanges } from '@monorepo_2022/app-utils/src/hooks/useDetectRevisionChanges';
import type { Namespace } from 'app/helpers/featureFlag';
import { featureFlag } from 'app/helpers/featureFlag';

export const meta: MetaFunction = () => {
  return [
    { charSet: 'utf-8' },
    { title: 'Coach App | Conocer' },
    { name: 'viewport', content: 'width=device-width,initial-scale=1' },
  ];
};

export const links: LinksFunction = () => {
  return [
    { rel: 'icon', type: 'image/svg+xml', href: '/favicon.svg' },
    {
      rel: 'apple-touch-icon',
      type: 'image/png',
      href: '/assets/appicons/apple-touch-icon.png?v1',
    },
    { rel: 'manifest', href: '/app.webmanifest?v1' },
  ];
};

export const loader = ({ context: { env } }: LoaderFunctionArgs) => {
  return json({
    env: {
      SENTRY_DSN: env.SENTRY_DSN,
      ENVIRONMENT: env.ENVIRONMENT,
      NAMESPACE: env.NAMESPACE,
    },
  });
};

export default function App() {
  const { i18n } = useTranslation();
  const { env } = useLoaderData<typeof loader>();
  const { state: navigationState } = useNavigation();
  const bodyRef = createRef<HTMLBodyElement>();
  const requireFullDocumentLoad = useDetectRevisionChanges(bodyRef);
  featureFlag.init(env.NAMESPACE as Namespace);

  return (
    <html lang={process.env.LOCALE} dir={i18n.dir()}>
      <head>
        <Meta />
        <Links />
      </head>
      <body data-build-revision={process.env.REVISION} ref={bodyRef}>
        <SnackbarProvider>
          <RequireFullDocumentLoadContext.Provider
            value={requireFullDocumentLoad}
          >
            <Outlet />
          </RequireFullDocumentLoadContext.Provider>
        </SnackbarProvider>
        <LinearProgressIndicator loading={navigationState !== 'idle'} />
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.__ENV = ${JSON.stringify(env)}`,
          }}
        />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return <ErrorPage caught={error} status={error.status || 404} />;
  } else if (error instanceof Error) {
    Sentry.captureException(error);
    return <ErrorPage error={error} status={500} />;
  } else {
    Sentry.captureException(new Error('Unexpected error', { cause: error }));
    return (
      <ErrorPage error={new Error('Unexpected error', { cause: error })} />
    );
  }
}
